<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <filter-table @setDataFilter="setData($event)"></filter-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <order-table :tableOptions="tableOptions" :enableDelete="true">
                    </order-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import filterTable from './orderMealsFilter.vue'
import orderTable from '../../../components/base/orderMealsTable.vue'
import { meal } from '../../../store/auth'
// import editCostCenter from "./editCostCenter.vue";
export default {
    components: {
        orderTable,
        filterTable
    },
    data() {
        return {
            meal,
            dialog: false,
            submitBtnLoading: false,
            name: "",
            params: {
                date_from: null,
                date_to: null,
                meal_id: null
            }
        };
    },
    methods: {
        setData(data) {
            meal.getOrderdMeals(data)
        },
    },

    computed: {
        tableOptions() {
            return meal.mealsOrderTable
        }
    },
    mounted() {
        meal.getOrderdMeals(this.params)
    }




};
</script>