<template>
  <div>
    <v-card elevation="0" class="px-10 py-15">
      <v-row>
        <v-col md="2">
          <input style="
              width: 150.22px;
              height: 40p;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
            " type="date" v-model="data.date_from" />
        </v-col>
        <v-col md="2">
          <input style="
              width: 150.22px;
              height: 40p;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
            " type="date" v-model="data.date_to" />
        </v-col>
        <v-col md="2">
          <v-autocomplete v-model="data.meal_id" :items="mealItems" item-text="name" item-value="id"
            :search-input.sync="mealSearch" :loading="meal.getMealsNameState.loading"
            :label="$t('meal name')"></v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-autocomplete :items="childrens" item-text="name" item-value="id" v-model="data.registration_record_id"
            :search-input.sync="studentSearch" :loading="childrensLoading" :label="$t('student name')"></v-autocomplete>
        </v-col>
        <v-col md="3" style="display: flex; justify-content: center">
          <v-col md="3">
            <v-icon large color="gray" @click="data = {}">mdi-close</v-icon>
          </v-col>
          <v-col md="3">
            <v-icon large color="gray" @click="setData"> mdi-magnify </v-icon>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
  
<script>
import { meal } from "../../../store/auth";
import axios from 'axios'
export default {
  data() {
    return {
      meal,
      data: {
        date_from: null,
        date_to: null,
        meal_id: null,
        registration_record_id: null,
      },
      menu1: false,
      menu2: false,
      mealSearch: null,
      studentSearch: null,
      childrens: [],
      childrensLoading: false,
    };
  },
  methods: {
    setData() {
      this.$emit("setDataFilter", this.data);
    },
  },
  computed: {
    mealItems() {
      return meal.mealData;
    },
  },
  watch: {
    async studentSearch(val) {
      this.childrensLoading = true;
      if (val != '' && val != null) {
        try {
          const searchResult = await axios.get("/student/auto-complete", {
            params: {
              name: val,
            },
          });
          this.childrens.push(...searchResult.data.data);
        } catch (err) {
          console.log("err", err);
        } finally {
          this.childrensLoading = false;
        }
      } else {
        this.childrens = []
      }

    },
    mealSearch(val) {
      if ((val != "") & (val != null)) {
        meal.getMealsName(val);
      } else {
        meal.mealData = [];
      }
    },
  },
};
</script>
  